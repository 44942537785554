//Card component for both portfolios and blog posts
const Card = ({ title, text, cardImgSrc }) => {
  return (
    <div className="">
      <a
        href="#"
        className="block mx-2 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <div className="overflow-hidden rounded-lg">
          <img
            src={cardImgSrc}
            height="150"
            width="150"
            className=" scale-[2.0] rounded-xl"
          ></img>
        </div>
        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {text}...
        </p>
        <p className="underline flex justify-center text-gray-500">Read more</p>
      </a>
    </div>
  );
};

export default Card;
