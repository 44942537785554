import Card from "./Card.js";

const Blog = () => {
  return (
    <div className="m-auto justify-evenly grid gap-4 grid-cols-2 pt-16">
      <Card
        title={"Will AI steal your job? 🤖"}
        text={"Probably not. But it will definitely displace jobs"}
      />{" "}
      <Card
        title={"Todos before using Tailwind 💨"}
        text={"Tailwind classes are chunky, so check out these 3 tips"}
      />{" "}
      <Card
        title={"Has innovation peaked? 🏔️"}
        text={"In terms of software, probably. But what about hardware?"}
      />{" "}
      <Card
        title={"Thoughts on the Rabbit R1 🐰"}
        text={"Humane pin was a flop. Are we still to early for ai devices?"}
      />
    </div>
  );
};

export default Blog;
