import Card from "./Card";

const Projects = () => {
  return (
    <div className="m-auto justify-evenly grid gap-4 grid-cols-2 pt-16">
      <Card
        title="GoFinance"
        text="Dynamic web app to track your portfolio"
        cardImgSrc="https://user-images.githubusercontent.com/73975219/196069436-e6f9ef5c-737c-44eb-a47a-f1aa8cf83f15.png"
      />{" "}
      <Card
        title="GoFinance"
        text="Dynamic web app to track your portfolio"
        cardImgSrc="https://user-images.githubusercontent.com/73975219/196069436-e6f9ef5c-737c-44eb-a47a-f1aa8cf83f15.png"
      />{" "}
      <Card
        title="GoFinance"
        text="Dynamic web app to track your portfolio"
        cardImgSrc="https://user-images.githubusercontent.com/73975219/196069436-e6f9ef5c-737c-44eb-a47a-f1aa8cf83f15.png"
      />{" "}
      <Card
        title="GoFinance"
        text="Dynamic web app to track your portfolio"
        cardImgSrc="https://user-images.githubusercontent.com/73975219/196069436-e6f9ef5c-737c-44eb-a47a-f1aa8cf83f15.png"
      />
    </div>
  );
};

export default Projects;
