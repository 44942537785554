const Home = () => {
  return (
    <header>
      <h1 className="text-5xl lg:text-7xl pt-[30vh] font-bold transition-all">
        I'm
        <span class="mx-6 font-extrabold text-5xl lg:text-7xl relative inline-block stroke-current">
          Amin
          <svg
            class="absolute -bottom-0.5 w-full max-h-1.5"
            className=""
            viewBox="0 0 55 5"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002"
              stroke-width="2"
            />
          </svg>
        </span>
      </h1>

      <div className="text-3xl lg:text-5xl pt-[10vh] font-semibold transition-all">
        <p className="hover:text-purple-200 p-[3vh]">Montrealer</p>
        <p className="hover:text-purple-200 p-[3vh]">Intrapreneur</p>
        <p className="hover:text-purple-200 p-[3vh]">Product Passionate</p>
        <p className="hover:text-purple-200 p-[3vh]">
          Full-Stack Web Developer
        </p>
      </div>
    </header>
  );
};

export default Home;
