import "./App.css";
import Blog from "./Blog.js";
import Home from "./Home.js";
import Projects from "./Projects.js";
import Menu from "./Menu.js";
import Footer from "./Footer.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <div class="bg-hero area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="text-white bg-gradient-to-b from-violet-600 via-violet-600 to-indigo-600 font-roboto min-h-[100vh] flex flex-column align-center text-center justify-center ">
        <Router>
          <Menu />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </div>
  );
};

export default App;
