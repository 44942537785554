import { TbSquareLetterA } from "react-icons/tb";
import { useState } from "react";

const Menu = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleClick = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div className="bg-[#4434d6] h-15 w-full flex justify-end items-center px-10 py-2 fixed shadow-[rgba(0,0,10,0.3)_0px_0px_10px_0px] font-montserrat font-semibold">
      <a href="/" aria-label="Navigate to Home">
        <TbSquareLetterA
          style={{
            width: "50px",
            height: "50px",
          }}
        />
      </a>
      {/* <button
        className="font-roboto text-2xl ml-auto "
        onClick={() => {
          handleClick();
        }}
      >
        {menuIsOpen ? "-" : "+"}
      </button> */}
      <div className="ml-auto">
        <span>[ </span>
        {/* <a
          href="/blog"
          // className="hover:underline hover:text-purple-500 hover:duration-300 "
        >
          Blog
        </a>
        <span> | </span> */}
        <a
          href="/projects"
          className="hover:underline hover:text-purple-500 hover:duration-300"
        >
          Projects
        </a>
        {/* <span> | </span>
        <a
          href="/"
          className="hover:underline hover:text-purple-500 hover:duration-300"
        >
          Contact
        </a> */}
        <span> ]</span>
      </div>
    </div>
  );
};

export default Menu;
