import { TbBrandLinkedin, TbBrandGithub } from "react-icons/tb";

const Footer = () => {
  return (
    <div className="fixed bottom-0 flex mx-auto pb-3 h-15 opacity-60">
      <a href="https://www.linkedin.com/">
        <TbBrandLinkedin
          style={{
            width: "50px",
            height: "50px",
          }}
        />
      </a>
      <a href="https://www.github.com">
        <TbBrandGithub
          style={{
            width: "50px",
            height: "50px",
          }}
        />
      </a>
    </div>
  );
};

export default Footer;
